@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$de-icon-burger: "\EA01"
$de-icon-cart: "\EA02"
$de-icon-close: "\EA03"
$de-icon-catalog-burger: "\EA04"
$de-icon-chevron-right: "\EA05"
$de-icon-chevron-up: "\EA06"
$de-icon-chevron-down: "\EA07"
$de-icon-chevron-left: "\EA08"
$de-icon-arrow-left: "\EA09"
$de-icon-search: "\EA13"
$de-icon-star: "\EA14"
$de-icon-check: "\EA15"
$de-icon-star-fill: "\EA16"
$de-icon-bookmark: "\EA17"
$de-icon-script: "\EA18"
$de-icon-box: "\EA19"
$de-icon-purse: "\EA20"
$de-icon-mail: "\EA21"
$de-icon-clock: "\EA22"
$de-icon-eye-on: "\EA23"
$de-icon-eye-off: "\EA24"
$de-icon-linkedin: "\EA25"
$de-icon-facebook: "\EA26"
$de-icon-twitter: "\EA27"
$de-icon-phone: "\EA28"
$de-icon-location: "\EA29"
$de-icon-user: "\EA30"
$de-icon-pen: "\EA31"
$de-icon-rounded-user: "\EA32"
$de-icon-exit: "\EA33"
$de-icon-pen-2: "\EA34"
$de-icon-whatsapp: "\EA35"
$de-icon-viber: "\EA36"
$de-icon-telegram: "\EA37"
$de-icon-tile: "\EA38"
$de-icon-placeholder: "\EA39"
$de-icon-like: "\EA40"
$de-icon-percent: "\EA41"
$de-icon-date: "\EA42"
$de-icon-time: "\EA43"
$de-icon-hourglass: "\EA44"
$de-icon-truck: "\EA45"
$de-icon-play: "\EA46"
$de-icon-hot: "\EA47"
$de-icon-new: "\EA48"
$de-icon-heart-empty: "\EA49"
$de-icon-heart-fill: "\EA50"


%de-icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.de-icon, [class^="icon-"], [class*=" icon-"]
	@extend %de-icon
	display: inline-block

.de-icon--type_burger:before
	content: $de-icon-burger
.de-icon--type_cart:before
	content: $de-icon-cart
.de-icon--type_close:before
	content: $de-icon-close
.de-icon--type_catalog-burger:before
	content: $de-icon-catalog-burger
.de-icon--type_chevron-right:before
	content: $de-icon-chevron-right
.de-icon--type_chevron-up:before
	content: $de-icon-chevron-up
.de-icon--type_chevron-down:before
	content: $de-icon-chevron-down
.de-icon--type_chevron-left:before
	content: $de-icon-chevron-left
.de-icon--type_arrow-left:before
	content: $de-icon-arrow-left
.de-icon--type_search:before
	content: $de-icon-search
.de-icon--type_star:before
	content: $de-icon-star
.de-icon--type_check:before
	content: $de-icon-check
.de-icon--type_star-fill:before
	content: $de-icon-star-fill
.de-icon--type_bookmark:before
	content: $de-icon-bookmark
.de-icon--type_script:before
	content: $de-icon-script
.de-icon--type_box:before
	content: $de-icon-box
.de-icon--type_purse:before
	content: $de-icon-purse
.de-icon--type_mail:before
	content: $de-icon-mail
.de-icon--type_clock:before
	content: $de-icon-clock
.de-icon--type_eye-on:before
	content: $de-icon-eye-on
.de-icon--type_eye-off:before
	content: $de-icon-eye-off
.de-icon--type_linkedin:before
	content: $de-icon-linkedin
.de-icon--type_facebook:before
	content: $de-icon-facebook
.de-icon--type_twitter:before
	content: $de-icon-twitter
.de-icon--type_phone:before
	content: $de-icon-phone
.de-icon--type_location:before
	content: $de-icon-location
.de-icon--type_user:before
	content: $de-icon-user
.de-icon--type_pen:before
	content: $de-icon-pen
.de-icon--type_rounded-user:before
	content: $de-icon-rounded-user
.de-icon--type_exit:before
	content: $de-icon-exit
.de-icon--type_pen-2:before
	content: $de-icon-pen-2
.de-icon--type_whatsapp:before
	content: $de-icon-whatsapp
.de-icon--type_viber:before
	content: $de-icon-viber
.de-icon--type_telegram:before
	content: $de-icon-telegram
.de-icon--type_tile:before
	content: $de-icon-tile
.de-icon--type_placeholder:before
	content: $de-icon-placeholder
.de-icon--type_like:before
	content: $de-icon-like
.de-icon--type_percent:before
	content: $de-icon-percent
.de-icon--type_date:before
	content: $de-icon-date
.de-icon--type_time:before
	content: $de-icon-time
.de-icon--type_hourglass:before
	content: $de-icon-hourglass
.de-icon--type_truck:before
	content: $de-icon-truck
.de-icon--type_play:before
	content: $de-icon-play
.de-icon--type_hot:before
	content: $de-icon-hot
.de-icon--type_new:before
	content: $de-icon-new
.de-icon--type_heart-empty:before
	content: $de-icon-heart-empty
.de-icon--type_heart-fill:before
	content: $de-icon-heart-fill
